import React, { Component } from "react";
import { Link } from "gatsby";

//import darkLogo from "../assets/images/resources/logo-dark.png";
// import logoDark from "../assets/images/global/logo.png";
import { APP_NAME, BASE_APP_URL, BASE_FRONT_URL } from "../config/constants";
import blogList from "../assets/data/blog.json";

const getFooterBlogList = () => {
  const footerBlogList = []
  for (let k in blogList) {
    footerBlogList.push(blogList[k])
    if (footerBlogList.length >= 5) {
      return footerBlogList
    }
  }
  return footerBlogList
}

const footerBlogList = getFooterBlogList()

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      scrollBtn: false
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }



  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 70) {
      this.setState({
        scrollBtn: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        scrollBtn: false
      });
    }
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <footer className="site-footer">
          <div className="site-footer__upper">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="footer-widget footer-widget__about">
                    <img
                      src={`/assets/images/global/logo.png`}
                      width="190"
                      alt={APP_NAME}
                      className="footer-widget__logo"
                    />
                    <p className="footer-widget__contact">
                      <a href="#">Ecommerce AI Automation LLC</a>
                    </p>

                    <p className="footer-widget__contact">
                      <a href="mailto:support@ecomscout.com">
                        support@ecomscout.com
                      </a>
                    </p>
                    <p className="footer-widget__contact">
                      2999 NE 191ST ST, STE 907, <br />Aventura, FL, 33180
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 d-flex justify-content-between footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Shortcuts</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <a href="#pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="#about">About US</a>
                      </li>
                      <li>
                        <a href={`${BASE_APP_URL}/privacy-policy`}>Privacy Policy</a>
                      </li>
                      <li>
                        <a href={`${BASE_APP_URL}/terms-conditions`}>Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="https://contact.ecomscout.io/">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 d-flex justify-content-between footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Blog</h3>
                    <ul className="footer-widget__links list-unstyled">
                      {
                        footerBlogList.map((item, index) => {
                          const uri = `/blog/${item.slug}`
                          return (
                            <li
                              key={index}
                            >
                              <Link to={uri} className="bottom-nav-blog-title" title={item.title}>
                                {item.title}
                              </Link>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-widget">
                    <div className="site-footer__social">
                      <a
                        href="https://www.facebook.com/profile.php?id=100093559869736"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                      >
                        <i className="fa fa-facebook-square"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/ecomscout.io/?hl=en"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="inner-container text-center">
                <p className="site-footer__copy">
                  <span>&copy; {`Copyright`} {new Date().getFullYear()} by{" "}</span>
                  <span><a href={BASE_FRONT_URL}>{`${APP_NAME}™`}</a>&nbsp;|&nbsp;</span>
                  <span><a href={`${BASE_APP_URL}/terms-conditions`} className="text-black" target="_blank">{`Terms & Conditions`}</a>&nbsp;|&nbsp;</span>
                  <span><a href={`${BASE_APP_URL}/privacy-policy`} className="text-black" target="_blank">{`Privacy Policy`}</a></span>
                </p>
              </div>
            </div>
          </div>
        </footer>

        <div
          onClick={this.scrollTop}
          onKeyDown={this.scrollTop}
          role="button"
          tabIndex="0"
          className="scroll-to-target scroll-to-top"
          style={{ display: this.state.scrollBtn ? "block" : "none" }}
        >
          <i className="fa fa-angle-up"></i>
        </div>
      </div>
    );
  }
}
