// any app specific constants that you don't want to keep in config can go here.
export const APP_NAME = "EcomScout";
export const REGISTER_FUNC = "enabled";

let base_site_url = "";
let base_api_url = "";
let socket_server_url = "";
let base_upload_url = "";
let base_front_url = ""
let base_app_url = ""
let base_shopify_url = ""

export const BACKEND_LOCATION = "prod"; //"localhost", "dev", "prod"

if (BACKEND_LOCATION === "localhost") {
    base_site_url = "http://192.168.0.55"
    base_api_url = base_site_url + ":8090"
    base_front_url = base_site_url + ":3000"
    base_app_url = base_front_url
    socket_server_url = base_api_url
    base_upload_url = base_site_url + ""
    base_shopify_url = "https://8cef-183-182-114-228.ngrok-free.app"
}
else if (BACKEND_LOCATION === "dev") {
    base_site_url = "https://dev.ecomscout.io"
    base_api_url = "https://dev-api.ecomscout.io"
    base_front_url = base_site_url + ""
    base_app_url = "https://dev-app.ecomscout.io"
    socket_server_url = base_api_url
    base_upload_url = base_site_url + ""
    base_shopify_url = base_api_url
}
else if (BACKEND_LOCATION === "prod") {
    base_site_url = "https://ecomscout.io"
    base_api_url = "https://api.ecomscout.io"
    base_front_url = base_site_url + ""
    base_app_url = "https://app.ecomscout.io"
    socket_server_url = base_api_url
    base_upload_url = base_site_url + ""
    base_shopify_url = base_api_url
}

export const BASE_SITE_URL = base_site_url
export const BASE_FRONT_URL = base_front_url
export const BASE_API_URL = base_api_url
export const BASE_APP_URL = base_app_url
export const SOCKET_SERVER_URL = socket_server_url
export const BASE_UPLOAD_URL = base_upload_url
export const BASE_SHOPIFY_URL = base_shopify_url


export const SITE_MODE = "live";
export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

export const WEBSITE_VERSION = "1.1";

//////////////////////////////////////////////////////////////////////////////////////////////////
export const ACCOUNT_TYPE = {
    BRAND: "brand",
    AGENCY: "agency"
}
export const HEAR_FROM_LIST = [
    {value: "none", text: "None"},
    {value: "facebook_ad", text: "Facebook Ad"},
    {value: "google", text: "Google"},
    {value: "tiktok", text: "Tiktok"}
]
export const INDUSTRY_LIST = [
    {value: "art", text: "Art"},
    {value: "baby", text: "Baby"},
    {value: "books", text: "Books"},
    {value: "car_truck", text: "Car & Truck Parts"},
    {value: "cell_phones", text: "Cell Phones & Accessories"},
    {value: "clothing", text: "Clothing"},
    {value: "collectibles", text: "Collectibles"},
    {value: "computers", text: "Computers"},
    {value: "crafts", text: "Crafts"},
    {value: "digital_products", text: "Digital Products"},
    {value: "electronics", text: "Electronics"},
    {value: "fashion_accessories", text: "Fashion Accessories"},
    {value: "food_beverages", text: "Food & Beverages"},
    {value: "health_beauty", text: "Health & Beauty"},
    {value: "home_garden", text: "Home & Garden"},
    {value: "jewelry_watches", text: "Jewelry & Watches"},
    {value: "office_products", text: "Office Products"},
    {value: "pet_supplies", text: "Pet Supplies"},
    {value: "shoes", text: "Shoes"},
    {value: "sporting_goods", text: "Sporting & Goods"},
    {value: "toys_hobbies", text: "Toys & Hobbies"}
]
export const ROLE_LIST = [
    {value: "ad_buyer", text: "Ad Buyer"},
    {value: "agency_owner", text: "Agency Owner"},
    {value: "brand_owner", text: "Brand Owner"},
    {value: "marketing_manager", text: "Marketing Manager"},
    {value: "retention_marketer", text: "Retention Marketer"},
    {value: "operations", text: "Operations"},
    {value: "finance_accounting", text: "Finance/Accounting"},
    {value: "inventory_supply_chain", text: "Inventory/Supply Chain"}
]
export const PLAN_TYPE = {
    MONTHLY: "monthly",
    YEARLY: "yearly"
}
export const ANNUAL_RENENUE_LIST = [
    {value: "1", text: "Less than $1M TTM Revenue"},
    {value: "5", text: "$1M - $5M TTM Revenue"},
    {value: "10", text: "$5M - $10M TTM Revenue"},
    {value: "15", text: "$10M - $15M TTM Revenue"},
    {value: "20", text: "$15M - $20M TTM Revenue"},
    {value: "20+", text: "$20M+ TTM Revenue"},
]

export const FB_SETTING_ATTRIBUTION_WINDOWS = [
    {value: "1d_click", text: "1-Day Click"},
    {value: "7d_click", text: "7-Day Click"},
    {value: "1d_click,1d_view", text: "1-Day Click or View"},
    {value: "7d_click,1d_view", text: "7-Day Click or 1-Day View"},
]

