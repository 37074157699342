import React from "react";
import Helmet from "react-helmet";
import "react-modal-video/css/modal-video.min.css";
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/font-awesome.min.css";
import "../assets/plugins/dimon-icons/style.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/custom.css";

import favIcon from "../assets/images/favicon.png";
import toast, { Toaster } from 'react-hot-toast';

const Layout = (props) => {

  return (
    <div>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="UTF-8" />
        <title>{props.pageTitle}</title>
        {/* <link rel="icon" type="image/png" sizes="32x32" href={`https://ecomscout.io/favicon.png`} /> */}

        {/* generated from https://realfavicongenerator.net/ */}
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />


        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="description" content="Seamlessly streamline analytics, attribution, merchandising, and forecasting. Experience excellence at your fingertips." />
        <meta name="keywords" content="EcomScout, AI data platform, e-commerce analytics, machine learning insights, predictive modeling" />
        <meta name="author" content="EcomScout" />
        <meta name="robots" content="noodp, noydir" />

        <link rel="canonical" href="https://ecomscout.io/" />

        <meta property="og:title" content="EcomScout | AI-Powered Ecommerce Data Platform" />
        <meta property="og:type" content='website' />
        <meta property="og:url" content="https://ecomscout.io/" />
        <meta property="og:description" content="EcomScout | AI-Powered Ecommerce Data Platform" />
        <meta property="og:image" content="https://ecomscout.io/ecomscout_thumb.jpg" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:site_name" content="EcomScout" />

        <link href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap" rel="stylesheet" />
      </Helmet>

      <div className="page-wrapper">{props.children}</div>

      <Toaster
        position="top-center"
        toastOptions={{
          className: '',
        }}
      />
    </div>
  )
}
export default Layout;
